export const isMinimumDifferent = (a, b, minDiff = 3) => {
	a = a.trim().toLowerCase();
	b = b.trim().toLowerCase();

	if (a === b) {
		return false;
	}

	if (Math.abs(a.length - b.length) >= minDiff) {
		return true;
	}

	const longestLength = a.length > b.length ? a.length : b.length;
	let diffCounter = 0;

	for (let i = 0; i < longestLength; i++) {
		if (a[i] !== b[i]) diffCounter++;

		if (diffCounter >= minDiff) break;
	}

	return diffCounter >= minDiff;
};

export const formatAddress = (suggestion) => {
	return suggestion?.replace(/^\w/, (m) => m.toUpperCase())?.replace(/[\s.][\w]/g, (m) => m.toUpperCase());
};
